<template lang="pug">
include ../pug/svg
section#learning
	div#ctrl
		div
			Search(v-model:search="search")
			Filter(id="statusFilter" label="Status" title="Status" :options="statusFilters" v-model:selected="statusFilter")
			button.but.circ.plus.text(v-if="hasFeature('course','add')" type="button" @click="toggleModal(true)")
				span Add {{terminology('course','course','singular')}}
				+svg(svg-filename="iconPlus" aria-hidden="true" alt="Add icon")
			//-ul.layout
				li
					button.but.grid(type="button")
						span Grid View
						+svg(svg-filename="iconGrid")
				li
					button.but.list(type="button")
						span List View
						+svg(svg-filename="iconList")
	div.wrapper
		//-pre {{courses}}
		div.c1.listbg
			ul.list
				li.course(v-for="(course, index) in filteredCourses" :key="course.id" :course="course" :index="index" @mouseenter="previewIndex=index" :class="{active:previewIndex===index}")
					picture
						img(v-if="course.media" :src="course.media.mediaUrl" :alt="course.media.seoAlt")
						img(v-else-if="course.mediaEmbedUrl" :src="course.mediaEmbedUrl")
					div.hgroup
						h2 {{course.name}}
						h3 {{courseStatus(course.publishStatus)}}
					a.arrow View {{terminology('course','course','singular')}}
						+svg(svg-filename='iconArrow')
					div(v-if="userCourseRole(course.id).seniority<=40").butmore
						router-link(v-if="hasFeature('course','content')" :to="{name:'ManageLearningCourseContent', params:{courseId:course.id}}") Edit Content
						router-link(v-else :to="{name:'ManageLearningCourseActivity', params:{courseId:course.id}}") View Activity
						button.but(type="button" aria-haspopup="true" aria-controls="" @mouseenter="showTint(),setMenu($event)" @mouseleave="hideTint") More
							+svg(svg-filename="iconMore" aria-hidden="true" alt="More icon")
						nav.menu(@mouseenter="showTint" @mouseleave="hideTint")
							div.bar
								+svg(svg-filename="iconMore" aria-hidden="true")
								button.but(type="button") Close
									+svg(svg-filename="iconClose")
							ul
								li(v-if="hasFeature('course','settings')")
									router-link(:to="{name:'ManageLearningCourseSettings', params:{courseId:course.id}}") Edit Settings
								li(v-if="hasFeature('course','access')")
									router-link(:to="{name:'ManageLearningCourseAccess', params:{courseId:course.id}}") Edit Access
								li(v-if="hasFeature('course','activity') && hasFeature('course','content')")
									router-link(:to="{name:'ManageLearningCourseActivity', params:{courseId:course.id}}") View Activity
								//-li
									router-link(:to="{name:'ManageLearningCourseFeedback', params:{courseId:course.id}}") View Feedback
								li(v-if="hasFeature('course','archive')")
									a(@click="archiveCourse(course)").bin Archive {{terminology('course','course','singular')}}
										+svg(svg-filename="iconBin" aria-hidden="true" alt="Bin icon")
					router-link.but.pri(v-else :to="{name:'ManageLearningCourseActivity', params:{courseId:course.id}}") View Activity
		div.c2.sticky(v-if="previewCourse")
			div.preview
				figure
					picture
						img(v-if="previewCourse.media" :src="previewCourse.media.mediaUrl" :alt="previewCourse.media.seoAlt")
						img(v-else-if="previewCourse.mediaEmbedUrl" :src="previewCourse.mediaEmbedUrl")
					figcaption
						span {{previewCourse.name}}
				div.body
					//-h5 Learning Summary
					//-picture.user(v-if="user.profileUrl" :class="{online:user.online}")
						img(:src="user.profileUrl" width="40" height="40" :alt="user.fullName")
					h5 Content Summary
					table.numeric
						tr
							th {{terminology('course','section','plural')}}
							th {{terminology('course','lesson','plural')}}
							th {{terminology('course','block','plural')}}
						tr
							td {{previewCourse.courseSections.length}}
							td {{previewCourse.courseLessons.length}}
							td {{lessonBlockCount(previewCourse)}}
					h5 {{terminology('user','user','singular')}} Summary
					table.numeric
						tr
							th {{terminology('user','admin','plural')}}
							th {{terminology('user','mentor','plural')}}
							th {{terminology('user','learner','plural')}}
						tr
							td {{courseAdminCount(previewCourse)}}
							td {{courseMentorCount(previewCourse)}}
							td {{courseLearnerCount(previewCourse)}}
					h5 Avg. Feedback Score
					ol.rating
						li(:class="avgFeedbackClass(previewCourse, 0)") 1 Star
							+svg(svg-filename="iconStar" aria-hidden="true" alt="Star icon")
						li(:class="avgFeedbackClass(previewCourse, 1)") 2 Star
							+svg(svg-filename="iconStar" aria-hidden="true" alt="Star icon")
						li(:class="avgFeedbackClass(previewCourse, 2)") 3 Star
							+svg(svg-filename="iconStar" aria-hidden="true" alt="Star icon")
						li(:class="avgFeedbackClass(previewCourse, 3)") 4 Star
							+svg(svg-filename="iconStar" aria-hidden="true" alt="Star icon")
						li(:class="avgFeedbackClass(previewCourse, 4)") 5 Star
							+svg(svg-filename="iconStar" aria-hidden="true" alt="Star icon")
					template(v-if="previewCourse.examMode")
						h5 Result Summary
						table.numeric
							tr
								th Attempts
								th Passes
								th Fails
							tr
								td {{courseAttempCount(previewCourse)}}
								td {{coursePassCount(previewCourse)}}
								td {{courseFailCount(previewCourse)}}
	//-pre courses: {{courses}}
	div.modal.center(:class="{active:showModal}")
		//-@mouseleave="toggleModal(false)"
		div.bar
			+svg(svg-filename="iconMore" aria-hidden="true")
			span Add {{terminology('course','course','singular')}}
			button.but(type="button" @click="toggleModal(false)") Close
				+svg(svg-filename="iconClose")
		div.body
			Form.fgroup(ref="form" :validation-schema="courseSchema" v-slot="{ errors, isSubmitting, values, meta }" @submit="addCourse")
				//-pre values: {{values}}
				div.field.grow
					Field(name="name" type="text" placeholder="Unsupplied" autocomplete="off" :validateOnInput="false" required)
					label Name
					transition(name="fade")
						ErrorMessage.err(name="name" as="span")
				div.foot
					span.req Required
					button.but.pri.spinner(type="submit" :class="{spin:isSubmitting}" :disabled="!meta.valid||isSubmitting") Save
					button.but.sec(type="button" @click="toggleModal(false)") Cancel
</template>

<script>
import Search from '../components/Search';
import Filter from '../components/Filter';
import CourseService from '../services/CourseService';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';

export default {
	name: 'ManageLearning',
	components: {
		Form,
		Field,
		ErrorMessage,
		Search,
		Filter,
	},
	mounted() {
		this.$store.dispatch('gui/setHeader', {
			title: 'Manage ' + this.terminology('course','course','plural'),
			backRoute: {
				text: 'Dashboard',
				name: 'Dashboard',
			},			
		});
		
		this.refreshCourses();
	},
	data() {
		const courseSchema = Yup.object().shape({
			name: Yup.string().label('Name').required(),
		});

		return {
			courseSchema,
			showModal: false,
			search: null,
			statusFilter: null,
			previewIndex: 0,
		}
	},
	computed: {
		usr() {
			return this.$store.state.auth.user;
		},
		userSeniority() {
			return this.$store.getters['auth/getUserSeniority'];
		},
		courses() {
			return this.$store.getters['courses/getCourses'];
		},
		statusFilters() {
			return this.$store.state.courses.courseStatuses;
		},
		filteredCourses() {
			// apply status filter
			const selected = this.statusFilter?.value || null;
			let f1 = (selected) ? this.courses.filter(c => c.publishStatus === selected) : this.courses.filter(c => c.publishStatus !== 'archive');
			
			// exclude courses auth user is asigned to
			let f2 = f1.filter(c => !c.courseUsers.some(u => u.userOrganisation.userId === this.usr.id && u.role.slug === 'learner'));
//			let f3 = f1.filter(c => !c.courseUsers.some(u => u.userOrganisation.userId === this.usr.id));
			
			// serch term filter
			return (this.search) ? f2.filter(c => c.name.toLowerCase().includes(this.search.toLowerCase())) : f2;
		},
		previewCourse() {
			return this.filteredCourses[this.previewIndex];
		},
	},
	methods: {
		hasFeature(feature, subfeature) {
			return this.$store.getters['gui/hasFeature'](feature, subfeature);
		},
		userCourseRole(courseId) {
			return this.$store.getters['courses/getUserCourseRole'](courseId);
		},
		setMenu(event) {
			if (event.target.nodeName === 'BUTTON' && event.relatedTarget.nodeName !== 'NAV') {
				const menu = event.target.nextSibling;
				menu.classList.remove('reverse');				
				const rect = menu.getBoundingClientRect();
				
				if (rect.bottom > window.innerHeight) {
					menu.classList.add('reverse');
				}
			}
		},
		terminology(groupKey, termKey, varKey) {
			return this.$store.getters['gui/customTerm'](groupKey, termKey, varKey);
		},
		lessonBlockCount(course) {
			const blocks = course.courseLessons.map(l => l.blockData.blocks);
			let count = 0;
			
			for (let block of blocks) {
				count += block.length;
			}
			
			return count;
		},
		courseStatus(publishStatus) {
			const status = this.statusFilters.find(s => s.value === publishStatus);
			return status.option;
		},
		courseAdminCount(course) {
			const admins = course.courseUsers.filter(u => u.role.slug === 'admin');
			return admins.length;
		},
		courseMentorCount(course) {
			const mentors = course.courseUsers.filter(u => u.role.slug === 'mentor');
			return mentors.length;
		},
		courseLearnerCount(course) {
			const learners = course.courseUsers.filter(u => u.role.slug === 'learner');
			return learners.length;
		},
		avgFeedbackClass(course, rating) {
			const score = this.$store.getters['courses/getAvgFeedbackScore'](course.id);
			
			if (score >= rating+1) {
				return 'full';	
				
			} else if (score >= rating)  {
				return 'half';
			}
			
			return null;
		},
		courseAttempCount(course) {
			return this.$store.getters['courses/getCompletedAttemptCount'](course.id);
		},
		coursePassCount(course) {
			return this.$store.getters['courses/getCompletedPassCount'](course.id);
		},
		courseFailCount(course) {
			return this.$store.getters['courses/getCompletedFailCount'](course.id);
		},
		toggleModal(bool) {
			this.showModal = bool;

			if (this.showModal) {
//				this.$refs.form.resetForm();
				this.showTint();
			} else {
				this.hideTint();
			}
		},
		showTint() {
			this.$store.dispatch('gui/setTintState', true);
		},
		hideTint() {
			this.$store.dispatch('gui/setTintState', false);
		},
		slugify(string) {
			return string.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'');
		},
		async addCourse(values, actions) {
			try {
				const response = await CourseService.createCourse({
					name: values.name,
					slug: this.slugify(values.name),
				});

				if (response.status === 200) {
					this.toggleModal(false);
					this.refreshCourses();
					actions.resetForm();
				}

			} catch(err) {
				actions.setFieldError('name', err.response.data.message);
			}
		},
		async archiveCourse(course) {
			await CourseService.archiveCourse({id:course.id});

			this.refreshCourses();
		},
		async refreshCourses() {
			// load courses from db
			await this.$store.dispatch('courses/dbCourses');
		},
	},
}
</script>

<style lang="scss">
</style>
